'use client';

import React, { createContext, useContext, useState } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogDescription } from '@/components/ui/dialog';
import { TranslationsContext } from './TranslationsProvider';

// Create a context for managing modal state
const ModalContext = createContext({});

export let openModal = (title: string, msg: string, callback = async () => { }) => { };
export let closeModal = () => { };
let cb: any = async () => { };

export const ModalProvider = ({ children }: any) => {
    const [isOpen, setIsOpen] = useState(false);
    const [title, setTitle] = useState('');
    const [message, setMessage] = useState('');
    const { dictionary }: any = useContext(TranslationsContext);

    // Function to open the modal with a custom message
    openModal = async (title: string, msg: string, callback = async () => { }) => {
        /* await callback(); */
        cb = callback;
        setTitle(title);
        setMessage(msg);
        setIsOpen(true);
    };

    // Function to close the modal
    closeModal = async () => {
        await cb();
        setTitle('');
        setMessage('');
        setIsOpen(false);
    };

    return (
        <ModalContext.Provider value={{ openModal, closeModal }}>
            {children}
            {/* Modal component that can be reused across the app */}
            <Dialog open={isOpen} onOpenChange={setIsOpen}>
                <DialogContent>
                    <DialogTitle>{title ?? ''}</DialogTitle>
                    <DialogDescription>
                        {message}
                    </DialogDescription>
                    <div className="mt-4">
                        <button
                            className="px-4 py-2 bg-red-600 text-white rounded hover:bg-red-700"
                            onClick={closeModal}
                        >
                            {dictionary?.onboarding?.dialog?.actions?.close ?? 'Chiudi'}
                        </button>
                    </div>
                </DialogContent>
            </Dialog>
        </ModalContext.Provider>
    );
};

// Custom hook to use the modal context
export const useModal = () => {
    return useContext(ModalContext);
};
