import React, { Children, ReactElement, ReactNode, useContext, useEffect } from 'react';

import "@/styles/globals.css"

import type { AppProps } from 'next/app'
import { Inter as FontSans } from "next/font/google"
import { cn } from "@/lib/utils"


import { ThemeProvider } from '@/components/providers/ThemeProvider';
import { TooltipProvider } from '@radix-ui/react-tooltip';

import { NextPage } from 'next';
import { getCookies, setCookie, deleteCookie, getCookie } from 'cookies-next';
import { useRouter } from 'next/navigation';
import { SessionProvider } from 'next-auth/react';
import UserProvider, { UserContext } from '@/components/providers/UserProvider';
import TranslationsProvider from '@/components/providers/TranslationsProvider';
import { SWRConfig } from 'swr';
import fetcher from '@/services/fetcher';
import { logoutFetch, logoutFetchDemo } from '@/services/api/auth.service';
import { getMainUser, getMainUserFetch, handleMainUser } from '@/services/api/profiles.service';
import { ModalProvider, useModal } from '@/components/providers/ModalProvider';


const mainHeaderConfig = [
    { name: 'home', label: 'Home', href: '/' },
    { name: 'about', label: 'About', href: '/about' },
    { name: 'contact', label: 'Contact', href: '/contact' },
]


const fontSans = FontSans({
    subsets: ["latin"],
    variable: "--font-sans",
})

export type NextPageWithLayout<P = {}, IP = P> = NextPage<P, IP> & {
    getLayout?: (page: ReactElement) => ReactNode;
}

type AppPropsWithLayout = AppProps & {
    Component: NextPageWithLayout;
}

let modalContext: any = null;

export let getGlobalRouter: any = () => { };

export default function App({ Component, pageProps: { session, ...pageProps } }: AppPropsWithLayout) {

    const getLayout = Component.getLayout ?? ((page) => page)

    const router = useRouter();

    getGlobalRouter = () => router;

    modalContext = useModal();

    useEffect(() => {
        /* if (!getCookie('NEXT_LOCALE')) {
            setCookie('NEXT_LOCALE', navigator.language);
            router.refresh();
        } */

        (async () => {
            /* if (getCookie('TOKEN') && !getCookie('CURRENT_USER')) {
                const result = await getMainUserFetch();
                if (result?.status === 200) {
                    router.push('/catalogue/');
                }
            } */

            if (!getCookie('TOKEN') && !getCookie('CURRENT_USER')) {
                const result = await logoutFetch();
                if (result?.status === 200) {
                    router.push('/onboarding/login/');
                }
            }

            /* if (getCookie('TOKEN') && getCookie('CURRENT_USER')) {
                router.push('/administration/');
            } */
        })();
    }, [router]);

    return (
        <SWRConfig
            value={{
                fetcher: fetcher, // Use Axios fetcher globally
                onError: (error) => {
                    if (error.response?.status === 401 || error.response?.status === 403) {
                        router.push('/onboarding/login/'); // Redirect to login if unauthorized
                    } else {
                        console.error('SWR Error:', error);
                        /* alert('Something went wrong, please try again.'); */
                    }
                },
                revalidateOnFocus: false,
            }}
        >
            <UserProvider>
                <ThemeProvider
                    attribute="class"
                    defaultTheme="unieuro"
                    enableSystem
                    themes={['dark', 'unieuro']}
                    disableTransitionOnChange
                >
                    {/* <SessionProvider session={session}> */}
                    <TranslationsProvider>
                        <ModalProvider>
                            <TooltipProvider delayDuration={0}>
                                <div className={cn(
                                    "min-h-screen h-full bg-background font-sans antialiased flex flex-col",
                                    /* fontSans.variable */
                                )}
                                >
                                    {getLayout(<Component {...pageProps} />)}
                                </div>
                            </TooltipProvider>
                        </ModalProvider>
                    </TranslationsProvider>
                    {/* </SessionProvider> */}
                </ThemeProvider>
            </UserProvider>
        </SWRConfig>
    )
}

export const getModalContext = () => {
    return modalContext;
}
